import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Cart } from 'src/app/core/models/cart.model';
import { UserInfo } from 'src/app/core/models/user-info.model';
import { Client } from 'src/app/core/models/client.model';
import { updateMinBoxesUser, updateMinPurchase, updateMinPurchaseFrozen } from 'src/app/core/state/actions/cart.actions';
import { CartService } from 'src/app/core/services/cart.service';

@Component({
  selector: 'app-ka-minimun-purchase',
  templateUrl: './ka-minimun-purchase.component.html',
  styleUrls: ['./ka-minimun-purchase.component.scss'],
})
export class KaMinimunPurchaseComponent implements OnDestroy, AfterViewInit {
  readonly ROOT = 'NEW_ORDER.RIGHT_SIDEBAR.';
  percentNumberBoxes;
  percentAmount;
  cart: Cart;
  user: UserInfo;
  percentAmountFrozen;
  client: Client;
  private subscriptions = new Subscription();

  constructor(private store: Store<{ user: UserInfo; client: Client; cart: Cart }>, private cartService: CartService) {
    this.subscriptions.add(
      this.store.select('cart').subscribe((cart) => {
        this.cart = cart;
        this.percentAmount = (this.cart.totalPriceNotFrozen / this.cart.minPurchase) * 100;
        this.percentAmountFrozen = (this.cart.totalPriceFrozen / this.cart.minPurchaseFrozen) * 100;
        this.percentNumberBoxes = (this.cart.totalBoxesUser / this.cart.minBoxesUser) * 100;
      })  
    );

    this.subscriptions.add(
      this.store.select('client').subscribe((client) => {
        this.client = client;
        this.store.dispatch(updateMinPurchase({ minPurchase: this.client.amountMinRoute }));
        this.store.dispatch(updateMinBoxesUser({ minBoxesUser: this.client.quantityMinOffRoute }))
        this.store.dispatch(updateMinPurchaseFrozen({ minPurchaseFrozen: this.client.amountMinFrozen }));
      })
    );
   
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => {
        this.user = user;
      })
    );
  }

  ngAfterViewInit(): void {
    if (
      this.cart.hasDeliveryNotFrozenProducts && !this.cart.offRoute && this.cart.minPurchase !== 0 ||
      this.cart.hasDeliveryFrozenProducts && !this.cart.offRoute || 
      this.cart.offRoute
    ) {
      this.updateHeight('85');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();

    this.updateHeight('100');
  }

  private updateHeight(height: string): void {
    const element = document.getElementById('s2ProductsComponent');
    if (element) {
      element.style.height = `calc(var(--vh, 1vh) * ${height} - 40px)`;
    }
  }
}
