import {
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subscription, of } from 'rxjs';
import { DiscountScale } from 'src/app/core/models/discount-scale.model';
import { UserInfo } from 'src/app/core/models/user-info.model';
import { environment } from 'src/environments/environment';
import { Cart } from '../../../../core/models/cart.model';
import { Discount } from '../../../../core/models/discount.model';
import { CartService } from '../../../../core/services/cart.service';
import * as CartActions from '../../../../core/state/actions/cart.actions';
import { upsertProduct } from '../../../../core/state/actions/cart.actions';
import { Product } from 'src/app/core/models/product.model';
import { delay } from 'rxjs/operators';
import { Client } from 'src/app/core/models/client.model';

@Component({
  selector: 'app-product-scale-modal',
  templateUrl: './product-scale-modal.component.html',
  styleUrls: ['./product-scale-modal.component.scss'],
})
export class ProductScaleModalComponent implements OnInit {
  readonly ROOT_LANG = 'NEW_ORDER.BUTTON_ADD.';
  private subscriptions = new Subscription();
  @Input() public product: Discount;
  readonly LANG_ROOT = 'NEW_ORDER.DISCOUNTS.SCALE.';
  readonly pillMsg = 'NEW_ORDER.DISCOUNTS.SCALE.SAVE';
  cart: Cart;
  regularPrice = 0;
  currentScale: DiscountScale;
  nextScaleDiscount: DiscountScale;
  lastScale: DiscountScale;
  nextScale: DiscountScale;
  scales: DiscountScale[];
  user: UserInfo;
  sortScales: DiscountScale[];
  secondToLastSortScale: DiscountScale;
  productInCart: Product;
  maxOrderQuantity: number;
  tooltipText: string;
  tooltipOpened = false;
  client: Client;

  @ViewChild('tooltip') tooltip: NgbTooltip;
  @ViewChild('maxQuantityTooltip') maxQuantityTooltip: NgbTooltip;

  @HostListener('document:click', ['$event.target', '$event'])
  onClick(targetElement, event) {
    if (targetElement.id === 'quantitySelected') {
      event.preventDefault();
      this.tooltip.close();
    }
  }

  constructor(
    public activeModal: NgbActiveModal,
    private cartService: CartService,
    private store: Store<{ user: UserInfo; cart: Cart; client: Client }>,
  ) {
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => (this.user = user)),
    );
    this.subscriptions.add(
      this.store.select('cart').subscribe((cart) => (this.cart = cart)),
    );
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => (this.client = client)),
    );
  }

  ngOnInit(): void {
    this.scales = this.product?.requirements?.scales;
    this.lastScale = this.scales?.[this.scales?.length - 1];
    this.regularPrice = this.scales[0].price.listPrice;

    const imgName =
      this.user.cpgId +
      '_' +
      this.user.countryId +
      '_' +
      this.user.organizationId +
      '_';
    this.product.image =
      environment.WEB_DOMAIN +
      'images/products/' +
      imgName +
      this.product.requirements.erpProductId +
      '.png';
    this.searchProductOnCart();
    this.determineMaxOrderQuantity();
    this.updateScale();
    this.roundMaxValues();
    this.orderByMaxPriceScale();
  }

  searchProductOnCart() {
    this.productInCart = this.cart.discountProducts.find(
      (item) =>
        item.erpProductId === this.product?.requirements.erpProductId &&
        item.erpMeasureUnitId ===
          this.product?.requirements.scales[1].erpUnitMeasureId,
    );
    if (this.productInCart) {
      this.product.quantitySelected = this.productInCart.quantity;
    }
  }

  addProduct(): void {
    this.determineMaxOrderQuantity();
    if (this.product.quantitySelected >= this.maxOrderQuantity) {
      return this.showTooltipForThreeSeconds(this.maxQuantityTooltip);
    }
    if (this.product.quantitySelected > this.maxOrderQuantity) return;
    this.product = {
      ...this.product,
      quantitySelected: this.product.quantitySelected + 1,
    };
    this.updateScale();
  }

  removeProduct(): void {
    if (this.product.quantitySelected > 1) {
      this.product = {
        ...this.product,
        quantitySelected: this.product.quantitySelected - 1,
      };
      this.updateScale();
    }
  }

  updateScale() {
    const currentScaleIndex = this.scales.findIndex((scale) => {
      return (
        this.product.quantitySelected >= scale.min &&
        this.product.quantitySelected <= scale.max
      );
    });

    this.nextScale = this.scales[currentScaleIndex + 1];
    if (this.nextScale?.reward?.value)
      this.nextScaleDiscount = this.nextScale.price.finalPrice;
  }

  getTotalFinalPriceCC(): number {
    this.currentScale = this.scales.find((scale) => {
      return (
        this.product.quantitySelected >= scale.min &&
        this.product.quantitySelected <= scale.max
      );
    });
    const maxScaleDisc = this.lastScale.max;
    if (this.product.quantitySelected <= maxScaleDisc) {
      return this.currentScale
        ? this.product.quantitySelected * this.currentScale.price.finalPrice
        : this.product.quantitySelected * this.scales[0].price.finalPrice;
    } else {
      const discountPrice = maxScaleDisc * this.currentScale.price.finalPrice;
      const normalPrice =
        (this.product.quantitySelected - maxScaleDisc) *
        this.product.requirements.price.finalPriceWithoutDiscount;
      return discountPrice + normalPrice;
    }
  }

  addProductToCart(): void {
    const isSubUnitAvailable = this.client.subUnitAvailable;
    const cartProduct = {
      ...this.product.requirements,
      ...this.currentScale,
      quantitySelected: this.product.quantitySelected,
      erpMesureUnitId: this.product.requirements.scales[1].erpUnitMeasureId,
      subunitSelected: isSubUnitAvailable
        ? this.product.requirements.scales[1].erpUnitMeasureId
        : null,
      enabledToSellBySubUnit: isSubUnitAvailable,
    };

    if (this.cart.products && this.product && this.productInCart) {
      cartProduct.quantitySelected =
        this.product.quantitySelected - (this.productInCart?.quantity || 0);
    } else {
      cartProduct.quantitySelected = this.product.quantitySelected;
    }

    if (this.scales[1].deliveryType === 'deliveryfrozen') {
      this.store.dispatch(
        CartActions.deliveryFrozenActions({ product: cartProduct }),
      );
    } else {
      this.store.dispatch(upsertProduct({ product: cartProduct }));
      this.cartService.updateDeliveryProducts();
    }
    this.activeModal.close();
  }

  roundMaxValues(): void {
    if (this.scales) {
      this.scales = this.scales.map((scale) =>
        scale.max % 1 !== 0
          ? { ...scale, max: Math.max(Math.round(scale.max), 1) }
          : scale,
      );
    }
  }

  orderByMaxPriceScale() {
    this.sortScales = this.scales.sort(
      (a, b) => b.price.finalPrice - a.price.finalPrice,
    );
    this.secondToLastSortScale = this.sortScales?.[this.sortScales?.length - 2];
  }

  determineMaxOrderQuantity() {
    const isBottleSelected =
      this.product.requirements.scales[1].erpUnitMeasureId === 'BOT';
    this.maxOrderQuantity = isBottleSelected
      ? this.product.requirements.scales[1].maxOrderQuantityBottle
      : this.product.requirements.scales[1].maxOrderQuantityBox;
  }

  showTooltipForThreeSeconds(tooltip) {
    if (!tooltip.isOpen()) {
      of(tooltip.open())
        .pipe(delay(3000))
        .subscribe({ next: () => tooltip.close() });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
