import { Injectable, OnDestroy, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { from, of, Subscription } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { ProductsService } from '../../services/products.service';
import * as PortfolioActions from './../actions/portfolio.actions';
import { BERespModel } from '../../models/backend/BE-response.model';

@Injectable({
  providedIn: 'root',
})
export class PortfolioEffects implements OnDestroy {
  private subscriptions = new Subscription();

  constructor(
    private productsService: ProductsService,
    private actions$: Actions,
  ) {}

  loadPortfolio$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PortfolioActions.loadPortfolio),
      mergeMap((props) =>
        this.productsService
          .getPortfolioWithDiscountByClient(true, {}, props.offset)
          .pipe(
            switchMap((res: BERespModel) => {
              return [
                PortfolioActions.loadPortfolioSuccess({
                  products: res.data,
                  page: res.pagination.currentpage,
                  totalProducts: res.pagination.count,
                }),
              ];
            }),
            catchError((error) => {
              return of(PortfolioActions.loadPortfolioError({ error }));
            }),
          ),
      ),
    ),
  );

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
