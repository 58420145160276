import { createAction, props } from '@ngrx/store';
import { Discount } from '../../models/discount.model';
import { Product } from '../../models/product.model';
import { BERespErrorModel } from '../../models/backend/BE-response-error.model';

export const loadPortfolio = createAction(
  '[Portfolio] loadPortfolio',
  props<{ offset: number }>(),
);

export const loadPortfolioSuccess = createAction(
  '[Portfolio] loadPortfolioSuccess',
  props<{
    products: (Product | Discount)[];
    page: number;
    totalProducts: number;
  }>(),
);

export const loadPortfolioError = createAction(
  '[Portfolio] loadPortfolioError',
  props<{ error: BERespErrorModel }>(),
);
