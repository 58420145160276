import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Client } from 'src/app/core/models/client.model';
import { Product } from 'src/app/core/models/product.model';
import { Subscription } from 'rxjs';
import { Cart } from '../../../core/models/cart.model';
import * as CartActions from 'src/app/core/state/actions/cart.actions';
import { CartService } from '../../../core/services/cart.service';
import { Draft } from 'src/app/core/models/draft.model';
import * as ClientActions from 'src/app/core/state/actions/client.actions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DraftRequestModalComponent } from '../../modals/draft-request-modal/draft-request-modal.component';
import { Router } from '@angular/router';
import { SuggestedProducts } from '../../../core/models/client.model';

@Component({
  selector: 'app-ka-suggested-total-button',
  templateUrl: './ka-suggested-total-button.component.html',
  styleUrls: ['./ka-suggested-total-button.component.scss'],
})
export class KaSuggestedTotalButtonComponent implements OnInit, OnDestroy {
  public suggestedProduct: Product[];
  suggestedProductsAmount = 0;
  private subscriptions = new Subscription();
  public cart: Cart;
  public client: Client;
  public hasAllSuggestedAlreadyOnCart;
  public draft: Draft[];
  public isDraft: boolean;
  readonly ROOT_LANG = 'HOME.SUGGESTED_CAROUSEL';

  constructor(
    private store: Store<{ client: Client; cart: Cart }>,
    private cartService: CartService,
    private router: Router,
    private modalService: NgbModal,
  ) {
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => {
        this.client = client;
        this.suggestedProduct = client.data.suggestedProducts.products;
        this.suggestedProductsAmount = client.data.suggestedProducts.amount;
      }),
    );
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => {
        this.draft = client.data.drafts;
      }),
    );
    this.subscriptions.add(
      this.store.select('cart').subscribe((cart) => {
        this.cart = cart;
      }),
    );
  }

  ngOnInit(): void {
    this.store.dispatch(ClientActions.loadDrafts());
    this.hasAllSuggestedAlreadyOnCart = this.hasAllSuggestedProductsOnCart();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  addAllSuggestedProductsToCart(): void {
    let products = this.suggestedProduct.filter(
      (p) => p.quantitySelected !== 0 && p.availability,
    );
    // Valido si el cliente tiene un draft. si tiene, agrego los sugeridos que faltan y si no tiene paso directo al carrito.
    if (this.draft.length > 0 && this.draft[0]?.items.length > 0) {
      // Obtener productos sugeridos que no están en el draft.
      this.draft[0].items.forEach((cartProd) => {
        products = products.filter((sugProd) => {
          return (
            sugProd.productId !== cartProd.productId ||
            sugProd.quantitySelected > cartProd.quantity
          );
        });
      });
      // Agrego los productos sugeridos al draft.
      products.forEach((product) => {
        product.quantity = product.quantitySelected;
        this.draft[0].items.forEach((cartProduct) => {
          if (
            product.productId === cartProduct.productId &&
            cartProduct.quantity < product.quantity
          ) {
            product.quantity = product.quantity - cartProduct.quantity;
          }
        });
        this.draft[0].items.push(product);
      });
      this.hasAllSuggestedAlreadyOnCart = true;

      this.modalService.open(DraftRequestModalComponent, {
        windowClass: 'ngbmodal-centered',
      });
    } else {
      this.gotToPortfolio(products);
      this.hasAllSuggestedAlreadyOnCart = true;
    }
  }

  private gotToPortfolio(products: Product[]): void {
    if (this.router.url.includes('mcc')) {
      this.router.navigate(['/mcc/2']).then(() => {
        this.updateSuggestedProducts(products);
      });
    } else {
      this.router.navigate(['/main/nuevo-pedido/2']).then(() => {
        this.updateSuggestedProducts(products);
      });
    }
  }

  private hasAllSuggestedProductsOnCart(): boolean {
    const suggestedProducts = this.suggestedProduct;
    const draftProducts = this.draft.length > 0 ? this.draft[0].items : [];

    return suggestedProducts?.every((suggestedProduct) => {
      return draftProducts.find(
        (product) =>
          product.productId === suggestedProduct.productId &&
          product.quantity < suggestedProduct.quantity,
      );
    });
  }

  private updateSuggestedProducts(products: Product[]) {
    this.store.dispatch(CartActions.upsertMultipleProducts({ products }));
    this.cartService.updateDeliveryProducts();
  }
}