import { LoginSteps } from "../enums/login-steps.enum";

export const enum LOGIN_BUTTON_ACTION_TYPE {
  STEP = 'STEP',
  SERVICE = 'SERVICE',
}

export const enum SIGN_IN_BUTTON_ACTION_TYPE {
  LOGIN = 'login',
  CONFIRM_CODE = 'confirmCode',
  RESTORE_EMAIL = 'restoreEmail',
  RESTORE_EMAIL_CODE = 'restoreEmailCode',
  RESEND_CONFIRM_CODE = 'reSendConfirmCode',
  RESEND_EMAIL_CODE = 'reSendEmailCode',
  CONFIRM_USER = 'confirmUser',
  SEND_EMAIL = 'sendEmail',
  PASSWORD = 'password',
  RESTORE_PWD = 'restorePwd',
  CHANGE_PWD = 'changePassword'
}

export const enum UPDATE_PASSWORD_BUTTON_ACTION_TYPE {
  EMAIL = 'email',
  NEW_ACCOUNT = 'newAccount',
  CONFIRM_CODE = 'confirmCode',
  PASSWORD = 'password',
  CONFIRM_USER = 'confirmUser',
  UPDATE_PASSWORD_LANDING = 'updatePasswordLanding',
}

export interface LoginFormView {
  image?: string;
  stepName: LoginSteps;
  title: string;
  extraTitle?: string;
  extraSubtitle?: string;
  previousView?: SIGN_IN_BUTTON_ACTION_TYPE | UPDATE_PASSWORD_BUTTON_ACTION_TYPE;
  inputType?: LoginInputTypes;
  stepNumber?: number;
  subtitle?: string;
  hasInput?: boolean;
  hasBackButton?: boolean;
  separatorLine?: boolean;
  hasSocialFooter?: boolean;
  hasNewClientLink?: boolean;
  hasRegisterLink?: boolean;
  bodyButtons?: loginButton[];
  footerButtons?: loginButton[];
}

export enum LoginInputTypes {
  EMAIL = 'email',
  PHONE = 'phone',
  PASSWORD = 'password',
  CODE = 'code',
}

export interface loginButton {
  id?: string;
  label: string;
  style: string;
  action: SIGN_IN_BUTTON_ACTION_TYPE | UPDATE_PASSWORD_BUTTON_ACTION_TYPE;
  actionType: LOGIN_BUTTON_ACTION_TYPE;
  needsValidation?: boolean;
  showSvgIcon?: boolean;
}

export interface UserLocalMetadata {
  cpgId: string;
  organizationId: string;
  countryId: string;
}
