import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { Client } from '../../../core/models/client.model';
import { UserInfo } from '../../../core/models/user-info.model';

import { IsMobileService } from '../../../core/services/is-mobile/is-mobile.service';
import { ModalMobileSelectCommerceService } from '../ka-select-commerce/service/modal-mobile-select-commerce.service';
import { env } from 'src/app/app.component';
import { FEATURES } from 'src/environments/utils/env.model';
import { SelectClientService } from 'src/app/core/services/select-client.service';

@Component({
  selector: 'app-ka-show-commerce',
  templateUrl: './ka-show-commerce.component.html',
  styleUrls: ['./ka-show-commerce.component.scss'],
})
export class KaShowCommerceComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  clients: Client[];
  selectedClient: Client;
  commerceInfo: string;
  user: UserInfo;
  isDropdownOpen = false;

  get isMobile(): boolean {
    return this._isMobileService.isMobile;
  }

  get isSingleClient(): boolean {
    return this.clients?.length === 1;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event): void {
    const target = event.target as HTMLElement;
    if (
      !target.closest('.selected-address') &&
      !target.closest('.dropdown-list')
    ) {
      this.isDropdownOpen = false;
    }
  }

  constructor(
    private store: Store<{ user: UserInfo; client: Client }>,
    private _isMobileService: IsMobileService,
    private _modalMobileSelectCommerceService: ModalMobileSelectCommerceService,
    private selectClientService: SelectClientService,
  ) {
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => {
        this.clients = user.clients;
        this.user = user;
      }),
    );
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => {
        this.selectedClient = client;
        this.setCommerceInfo();
      }),
    );
  }

  ngOnInit(): void {
    this.setCommerceInfo();
  }

  setCommerceInfo() {
    const useFantasyName = env.isFeatureAvailable(
      FEATURES.COMMERCE_FANTASY_NAME,
    );

    const getCommerceInfo = (client: Client) =>
      useFantasyName && client.fantasyName
        ? client.fantasyName
        : `${client.street} ${client.doorNumber}`;

    this.commerceInfo = getCommerceInfo(this.selectedClient);

    this.clients = this.clients?.map((client) => ({
      ...client,
      commerceInfo: getCommerceInfo(client),
    }));
  }

  openSelectCommerceModal() {
    this._modalMobileSelectCommerceService.openModal();
  }

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  selectClient(client): void {
    if (client.clientId === this.selectedClient.clientId) {
      return;
    }
    this.selectClientService.selectClient(client);
    this.isDropdownOpen = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
