import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';

import { Portfolio } from '../../models/portfolio.model';

import * as PortfolioActions from '../actions/portfolio.actions';

export const getPortfolioFeatureState =
  createFeatureSelector<Portfolio>('portfolio');

export const getPortfolioProducts = createSelector(
  getPortfolioFeatureState,
  (state) => state.productsAndDiscountsList,
);

const initialState: Portfolio = {
  productsAndDiscountsList: [],
  primaryFilter: '',
  secondaryFilter: '',
  viewGrid: true,
  totalProducts: 0,
  page: 1,
  isSuggested: false,
  error: null,
};

export const portfolioReducer = createReducer<Portfolio>(
  initialState,
  on(PortfolioActions.loadPortfolio, (state): Portfolio => ({ ...state })),
  on(
    PortfolioActions.loadPortfolioSuccess,
    (state, props): Portfolio => ({
      ...state,
      productsAndDiscountsList: props.products,
      page: props.page,
      totalProducts: props.totalProducts,
    }),
  ),
);
