import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ProductTypes } from 'src/app/core/enums/product-types';
import { UserInfo } from 'src/app/core/models/user-info.model';
import { ProductsService } from 'src/app/core/services/products.service';
import { CountryCodes } from 'src/app/core/enums/country-codes.enum';
import { Cart } from 'src/app/core/models/cart.model';
import { ProductFilter } from 'src/app/core/models/product-filter.model';
import { INITIAL_PRODUCT_FILTERS } from 'src/app/core/state/reducers/cart.reducer';
import * as CartActions from '../../../core/state/actions/cart.actions';
import { environment } from 'src/environments/environment';
import { env } from 'src/app/app.component';
import { FEATURES } from 'src/environments/utils/env.model';
import { Client } from 'src/app/core/models/client.model';
import { ExternalIntegration } from 'src/app/pages/external-integration/enums/external-integration.enum';

@Component({
  selector: 'app-ka-categories-carousel',
  templateUrl: './ka-categories-carousel.component.html',
  styleUrls: ['./ka-categories-carousel.component.scss'],
})
export class KaCategoriesCarouselComponent implements OnInit, OnDestroy {
  @Output() suggested = new EventEmitter<void>();
  readonly ROOT_LANG = 'HOME.CATEGORIES_CAROUSEL.';
  readonly IMG_ROOT = environment.WEB_DOMAIN + 'images/';
  readonly ProductTypes = ProductTypes;
  readonly CountryCodes = CountryCodes;
  readonly ExternalIntegration = ExternalIntegration;
  private subscriptions = new Subscription();
  categories: string[];
  productFilters: ProductFilter;
  user: UserInfo;
  public currentCategory;
  @Input() page? = '';
  skeletonDummy;
  categoryButtonCarousel: boolean;
  categoryIconCarousel: boolean;
  hasSuggestedProducts = false;
  client: Client;
  isExternalUser = false;

  constructor(
    private productsService: ProductsService,
    private router: Router,
    private store: Store<{ user: UserInfo; cart: Cart; client: Client }>,
  ) {
    this.getCategories();
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => {
        this.user = user;
        this.isExternalUser =
          user?.authValue && user?.origin === ExternalIntegration.KOBOSS;
      }),
    );
    this.subscriptions.add(
      this.store.select('cart').subscribe((cart) => {
        this.productFilters = cart.productFilters;
        this.currentCategory = cart.productFilters.isDiscount
          ? 'promociones'
          : cart.productFilters.category;
      }),
    );
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => (this.client = client)),
    );
    this.skeletonDummy = Array(20)
      .fill(1)
      .map((x, i) => i);
  }

  ngOnInit(): void {
    this.loadAvailableFeatures();
    this.subscriptions.add(
      this.productsService.hasSuggestedProducts$.subscribe(
        (hasSuggestedProducts: boolean) => {
          this.hasSuggestedProducts = hasSuggestedProducts;
        },
      ),
    );
    this.updateHasSuggestedProductsStatus();
  }

  getCategoryImageName(category: string): string {
    const accentsMap: { [key: string]: string } = {
      á: 'a',
      é: 'e',
      í: 'i',
      ó: 'o',
      ú: 'u',
      ô: 'o',
    };
    return category
      .toLowerCase()
      .replace(/ /g, '_')
      .replace(/[áéíóúô]/g, (match: string) => accentsMap[match] || match);
  }

  private getCategories(): void {
    this.productsService.getPortfolioFilters().subscribe((res) => {
      this.categories = res.data.categories;
    });
  }

  goToCategory(category, isSelected: boolean) {
    this.cleanFilters();
    const route = this.user.uuid ? '/mcc/2' : '/main/nuevo-pedido/2';
    const filter = !isSelected ? category : null;
    this.router
      .navigate([route], { queryParams: { grupo: ProductTypes.UNIT, filter } })
      .then(() => {
        if (this.page !== 'home') window.location.reload();
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handleImageError(category) {
    const indexToRemove = this.categories.findIndex((i) => i === category);
    if (indexToRemove !== -1) this.categories.splice(indexToRemove, 1);
    this.categories = [...this.categories];
  }

  private cleanFilters() {
    const productFilters: ProductFilter = {
      ...INITIAL_PRODUCT_FILTERS,
    };

    this.updateProductFilterState(productFilters);
  }

  private updateProductFilterState(productFilters: ProductFilter): void {
    this.store.dispatch(
      CartActions.updateFilters({
        productFilters: { ...productFilters },
      }),
    );
  }

  loadAvailableFeatures(): void {
    this.categoryIconCarousel = env.isFeatureAvailable(
      FEATURES.CATEGORY_ICON_CAROUSEL,
    );
    this.categoryButtonCarousel = env.isFeatureAvailable(
      FEATURES.CATEGORY_BUTTON_CAROUSEL,
    );
  }

  goToCategorySuggested(idStep: string) {
    if (this.page === 'home') {
      const link = this.router.url.includes('mcc')
        ? '/mcc/' + idStep + '/'
        : '/main/nuevo-pedido/' + idStep + '/';

      this.router.navigate([link]);
    } else {
      this.suggested.emit();
    }
  }

  updateHasSuggestedProductsStatus() {
    this.hasSuggestedProducts =
      this.client.data.suggestedProducts.products &&
      this.client.data.suggestedProducts.products.length > 0;
  }
}
