<div
  class="w-100 white-layer pt-0 pt-lg-3 pr-5 pr-lg-0 pb-lg-5"
  [ngClass]="{ 'pt-lg-5': user.uuid }"
>
  <app-ka-navbar
    class="w-100"
    *ngIf="
      !user.uuid &&
      !(user.authValue && user.origin === ExternalIntegration.KOBOSS)
    "
  ></app-ka-navbar>

  <app-ka-show-commerce></app-ka-show-commerce>

  <div
    *ngIf="router.url.split('/')[2] !== 'home' && !user.uuid"
    class="pl-5 pt-5 pb-3 pt-lg-0"
    [ngClass]="{ 'pt-lg-3': user.uuid }"
  >
    <!-- Mis Promociones -->
    <div *ngIf="router.url.split('/')[2] === 'mis-promociones'">
      <p class="title mb-3 pt-2">
        {{ ROOT_LANG_BENEFITS + 'TITLE' | translate }}
      </p>
      <p class="subtitle mb-2">
        {{ ROOT_LANG_BENEFITS + 'SUBTITLE' | translate }}
      </p>
    </div>
  </div>
</div>
