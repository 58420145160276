import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, Subscription } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';

import * as UserActions from '../actions/user.actions';
import * as ClientActions from '../actions/client.actions';
import * as UserLocalActions from '../actions/user-local.actions';
import { CognitoService } from '../../services/cognito.service';
import { UserService } from '../../services/user.service';
import { UserInfo } from '../../models/user-info.model';
import { EncrDecrService } from '../../services/encr-decr.service';
import { environment } from 'src/environments/environment';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { BERespModel } from '../../models/backend/BE-response.model';
import { Client } from '../../models/client.model';

import { env } from 'src/app/app.component';
import { ModalsService } from '../../services/modals.service';
import { ExternalDataService } from 'src/app/pages/external-integration/services/external-data.service';
import { UserRole } from '../../enums/user-role';

@Injectable({
  providedIn: 'root',
})
export class UserEffects {
  private subscriptions = new Subscription();
  user: UserInfo;
  client: Client;
  role = UserRole;

  constructor(
    private actions$: Actions,
    private cognitoService: CognitoService,
    private userService: UserService,
    private externalDataService: ExternalDataService,
    private router: Router,
    private modalService: ModalsService,
    private store: Store<{ user: UserInfo; client: Client }>,
    private encrDecrService: EncrDecrService,
    private gtmService: GoogleTagManagerService,
  ) {
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => {
        this.user = user;
      }),
    );
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => {
        this.client = client;
      }),
    );
  }

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.logout),
      mergeMap(() =>
        this.cognitoService.signOut().pipe(
          map(() => UserActions.logoutSuccess()),
          catchError(() => of(UserActions.logoutError())),
        ),
      ),
    ),
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUser),
      mergeMap((props) =>
        this.userService.getUserInfo(props.cognitoUsername).pipe(
          switchMap((res) => [
            UserActions.updateUserSuccess({ user: res.data }),
          ]),
          catchError(() => of(UserActions.updateUserError())),
        ),
      ),
    ),
  );

  loginUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loginUser),
      mergeMap((props) =>
        this.userService.getUserInfo(props.cognitoUsername).pipe(
          switchMap((res) => {
            this.pushLoginEvent(res, props.loginEvent);
            this.router.navigate(['/main/home']);
            return [
              UserActions.updateUserSuccess({ user: res.data }),
              ClientActions.updateClientSuccess({
                client:
                  this.client.clientId !== 0
                    ? this.client
                    : res.data.clients[0],
              }),
              UserActions.loadRole({
                role:
                  this.client.clientId !== 0
                    ? this.client.rol
                    : res.data.clients[0].rol,
              }),
              ClientActions.loadInitClientSession(),
              UserActions.loginUserSuccess(),
            ];
          }),
          catchError((res) => {
            if (props.isSocial && res?.message === 'user_not_found') {
              this.router.navigate(['/login/sign-up'], {
                queryParams: { social: true },
              });
              return [UserActions.loginUserSuccess()];
            }

            return [
              UserActions.loginUserError(),
              UserLocalActions.updateloginError({ errorCode: res.code }),
              UserActions.logout(),
            ];
          }),
        ),
      ),
    ),
  );

  loginIntegrationUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loginIntegrationUser),
      mergeMap((props) =>
        this.userService.getUserInfo(props.userSession.username).pipe(
          switchMap((res: BERespModel) => {
            this.pushLoginEvent(res, 'login');
            const selectedClient = res.data.clients.find(
              (client) => String(client.clientId) === props.userSession.clientId,
            );
            return [
              UserActions.loadCognitoUserName({ cognitoUserName: res.data.username }),
              UserActions.updateUserSuccess({ user: res.data }),
              ClientActions.updateClientSuccess({
                client:
                  this.client.clientId !== 0 ? this.client : selectedClient,
              }),
              UserActions.loadRole({
                role:
                  this.client.clientId !== 0
                    ? this.client.rol
                    : selectedClient.rol,
              }),
              ClientActions.loadInitClientSession(),
              UserActions.loginIntegrationUserSuccess(),
            ];
          }),
          catchError(() => of(UserActions.loginIntegrationUserError())),
        ),
      ),
    ),
  );

  getIntegrationUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getIntegrationUserInfo),
      mergeMap((props) =>
        this.userService.getIntegrationUserInfo(props.userId).pipe(
          switchMap((res) => {
            this.cognitoService.signInMCC(res.data);
            const selectedCountry =
              env.getEnvironment().countriesConfig[res.data.countryId];
            return [
              UserLocalActions.loadGeoCountryCode({
                countryCode: res.data.countryId,
              }),
              UserLocalActions.loadOrganizationId({
                organizationId: selectedCountry.organizationId,
              }),
              UserActions.loadJwt({ jwt: res.data.IdToken }),
              UserActions.loadUuid({ uuid: res.data.id }),
              UserActions.loginIntegrationUser({ userSession: res.data }),
              UserActions.getIntegrationUserInfoSuccess(),
            ];
          }),
          catchError(() => {
            if (!this.user.uuid) this.router.navigate(['/reload-required']);
            return [
              UserActions.logout(),
              UserActions.getIntegrationUserInfoError(),
            ];
          }),
        ),
      ),
    ),
  );

  loginExternalIntegrationUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loginExternalIntegrationUser),
      mergeMap((props) =>
        this.externalDataService.validateExternalIntegrationUser(props.uuid).pipe(
          switchMap((res: BERespModel) => {
            const selectedCountry = env.getEnvironment().countriesConfig[res.data.countryId];
            return [
              UserActions.loadJwt({ jwt: res.data.IdToken }),
              UserLocalActions.loadGeoCountryCode({ countryCode: res.data.countryId }),
              UserLocalActions.loadOrganizationId({ organizationId: selectedCountry.organizationId }),
              UserActions.setExternalIntegrationUserInfo({ authValue: res.data.authValue, authType: res.data.authType, origin: res.data.origin }),
              UserActions.getExternalIntegrationUserInfo({ authValue: res.data.authValue, authType: res.data.authType, clientId: res.data.clientId }),
              UserActions.loginExternalIntegrationUserSuccess(),
            ];
          }),
          catchError(() => {
            if (!this.user.uuid) this.modalService.openSessionExpiredModal();
            return [
              //UserActions.logout(),
              UserActions.loginExternalIntegrationUserError(),
            ];
          }),
        ),
      ),
    ),
  );

  getExternalIntegrationUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getExternalIntegrationUserInfo),
      mergeMap((props) =>
        this.externalDataService.getExternalIntegrationUserInfo(props.clientId, props.authValue, props.authType).pipe(
          switchMap((res: BERespModel) => {
            const selectedClient = res.data.clients.find(
              (client) => String(client.clientId) === props.clientId,
            );
            return [
              UserActions.updateUserSuccess({ user: res.data }),
              ClientActions.updateClientSuccess({ client: this.client.clientId !== 0 ? this.client : selectedClient }),
              ClientActions.loadInitClientSession(),
              UserActions.getExternalIntegrationUserInfoSuccess(),
            ];
          }),
          catchError(() => of(UserActions.getExternalIntegrationUserInfoError())),
        ),
      ),
    ),
  );

  createUserSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.createUserSession),
      mergeMap((props) =>
        this.externalDataService.createUserSession(props.externalData).pipe(
          switchMap((res: BERespModel) => {
            return [
              ClientActions.loadInitClientSession(),
              UserActions.createUserSessionSuccess(),
            ];
          }),
          catchError(() => of(UserActions.createUserSessionError())),
        ),
      ),
    ),
  );

  deviceInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loginUserSuccess, UserActions.loginIntegrationUserSuccess),
      switchMap(() => {
        const cognitoUsername = this.user.cognitoUserName;
        const devicePlatform  = this.user.devicePlatform;
        const device          = this.user.device;        
        if (cognitoUsername && devicePlatform && device) {
          return this.userService.setUserInfoDevice(cognitoUsername, device, devicePlatform, true).pipe(
            map(() => UserActions.loadDeviceInfoSuccess()),
            catchError(() => of(UserActions.loadDeviceInfoError()))
          );
        } else {
          return of(UserActions.loadDeviceInfoSuccess());
        }
      })
    )
  );

  private pushLoginEvent(res, event?): void {
    const encryptedEmail = this.encrDecrService.encryptMCC(
      environment.AES_KEY_MCC,
      res.data?.email,
    );
    this.gtmService.pushTag({
      event,
      user: {
        ...res.data,
        jwt: '',
        clients: [],
        mail: encryptedEmail,
      },
      client: this.client.clientId !== 0 ? this.client : res.data?.clients[0],
    });
  }

}
