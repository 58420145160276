import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ModalMobileDeliveryDateService } from '../select-delivery-date/services/modal-mobile-delivery-date.service';
import { IsMobileService } from 'src/app/core/services/is-mobile/is-mobile.service';
import { NavbarMenuMobileService } from '../ka-navbar-menu-mobile/services/navbar-menu-mobile.service';

import { CountryCodes } from 'src/app/core/enums/country-codes.enum';
import { UserInfo } from 'src/app/core/models/user-info.model';
import { ModalMobileSelectCommerceService } from '../ka-select-commerce/service/modal-mobile-select-commerce.service';



@Component({
  selector: 'app-ka-navbar',
  templateUrl: './ka-navbar.component.html',
  styleUrls: ['./ka-navbar.component.scss'],
})
export class KaNavbarComponent implements OnDestroy, AfterViewInit {
  readonly CountryCodes = CountryCodes;
  private subscriptions = new Subscription();
  user: UserInfo;
  @ViewChild('navBar') navBar: ElementRef<HTMLDivElement>;
  @Output() navBarHeightEvent = new EventEmitter<number>();
  get isMobile(): boolean {
    return this.isMobileService.isMobile;
  }

  constructor(
    private store: Store<{ user: UserInfo }>,
    public modalMobileDeliveryDateService: ModalMobileDeliveryDateService,
    public modalMobileSelectCommerceService: ModalMobileSelectCommerceService,
    private isMobileService: IsMobileService,
    public navbarMenuMobileService: NavbarMenuMobileService
  ) {
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => (this.user = user)),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.calculateNavBarHeight();
    fromEvent(window, 'resize')
      .pipe(debounceTime(250))
      .subscribe(() => this.calculateNavBarHeight());
  }

  private calculateNavBarHeight(): void {
    const navBarHeight = this.navBar.nativeElement.offsetHeight;
    this.navBarHeightEvent.emit(navBarHeight);
  }
}
